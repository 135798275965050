import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/layout'
import Hero from '../components/hero'
import About from '../components/about'
import Form from '../components/form'
import Social from '../components/social'

class RootIndex extends React.Component {
  render() {
    const [hero] = get(this, 'props.data.allContentfulHero.nodes')
    const [company] = get(this, 'props.data.allContentfulCompany.nodes')

    return (
      <Layout location={this.props.location}>
        <Hero
          image={hero.heroImage.gatsbyImageData}
          title={hero.title}
        />
        <About company={company} />
        <Form />
        <Social />
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulHero {
      nodes {
        heroImage: image {
          gatsbyImageData(width: 1180, placeholder: BLURRED, layout: CONSTRAINED)
        }
        title
      }
    }
    allContentfulCompany {
      nodes {
        address
        capitalStock
        ceo
        name
        corporateNumber
        establishmentDate
        descriptionBusiness
      }
    }
  }
`
