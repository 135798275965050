import React, { useState } from 'react'
import { Link } from 'gatsby'
import { navigate } from "gatsby-link";
import { TextField, Button } from "@mui/material";

import Container from './container'
import * as styles from './form.module.css'

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const Form = () => {
  const [state, setState] = useState({});

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  return (
    <Container>
      <div className={styles.title}>Contact</div>
      <form
        name="contact"
        method="post"
        action="/thanks"
        className={styles.form}
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value="contact" className={styles.textform} />
        <label hidden>
          {" "}
          <input name="bot-field" onChange={handleChange} />
        </label>

        <TextField
          type="text"
          label="お名前"
          variant="outlined"
          name="name"
          onChange={handleChange}
          className={styles.textform}
          required
        />

        <TextField
          type="email"
          label="メールアドレス"
          variant="outlined"
          name="email"
          onChange={handleChange}
          className={styles.textform}
          required
        />

        <TextField
          name="message"
          label="本文"
          multiline
          rows={4}
          variant="outlined"
          onChange={handleChange}
          className={styles.textform}
          required
        />

        <Button type="submit" variant="contained" size="large" color="primary" className={styles.submitbtn}>
          送信する
        </Button>
      </form>
    </Container>
  );
};

export default Form;