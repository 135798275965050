import React from 'react'

import * as styles from './social.module.css'

const Social = () => (
  <div className={styles.social}>
    <a href="https://twitter.com/beryu" target="_blank">
      <img className={styles.twitter} src="../img/twitter.png"></img>
    </a>
    <a href="https://github.com/beryu" target="_blank">
      <img className={styles.github} src="../img/github.png"></img>
    </a>
  </div>
)

export default Social
