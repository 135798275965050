import React from 'react'

import Container from './container'
import * as styles from './about.module.css'

const About = ({ company }) => {
  if (!company) return null

  return (
    <Container>
      <div className={styles.title}>About</div>
      <dl className={styles.about}>
        <dt>会社名</dt><dd>{company.name}</dd>
        <dt>設立日</dt><dd>{company.establishmentDate}</dd>
        <dt>代表取締役</dt><dd>{company.ceo}</dd>
        <dt>事業内容</dt><dd>{company.descriptionBusiness}</dd>
        <dt>本社所在地</dt><dd>{company.address}</dd>
      </dl>
    </Container>
  )
}

export default About
